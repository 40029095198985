import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import Headroom from 'react-headroom';
import logo from '../../static/logo/header-logo.png';

const StyledLink = styled(Link)`
  display: flex;
  font-weight: 700;
  align-items: center;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: flex-end;
  //font-family: ${props => props.theme.fontFamily.body};
  font-weight: 500;
  font-size: 1.1rem;
  align-items: baseline;
  a {
    margin-left: 2rem;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    text-decoration: overline;
    text-shadow: 0 2px 2px black;
    color: ${props => props.theme.colors.white.base};
    margin-left: 2rem;
    transition: all ${props => props.theme.transitions.default.duration};
    &:hover {
      text-shadow: 1px 3px 3px #000000, 1px 3px 3px #ff0101, 1px 3px 3px #ff0101, 3px 1px 3px #ff0000, 3px 3px 1px #000000;
    }
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      text-decoration: none;
      border: 1px solid #831a17;
      width: 120px;
      text-align: center;
      border-radius: 10px;
      background: #831a17;
      margin: 1px;
      font-size: .8rem;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex-direction: column;
  }
`;

const NavBar = () => (
  <Headroom calcHeightOnResize disableInlineStyles>
    <StyledLink to="/">
      <img src={logo} alt="Gatsby Logo" />
    </StyledLink>
    <Nav>
      <Link to="/">Home</Link>
      <Link to="/blog">Blog</Link>
      <Link to="/about">About</Link>
      <Link to="/contact">Contact</Link>
    </Nav>
  </Headroom>
);

export default NavBar;
