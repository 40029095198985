import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Wrapper = styled.section`
  text-align: ${props => (props.center ? 'center' : '')};
  margin: auto;
  padding: 3rem 2rem;
  margin: 2rem auto;
  width: 80%;
  max-width: ${props => props.theme.layout[props.type]};
  height: 100%;
  flex: 1;
  background: #ffffff;
  border-radius: 20px;
  img {
    max-height: 300px;
  }

  .gatsby-resp-image-wrapper .gatsby-resp-image-background-image {
    padding-bottom: 45% !important;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 90%;
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 95%;
    padding: 10px;
    margin: 30px auto;
  }
`;

const Container = ({ children, type, className, center }) => (
  <Wrapper className={className} type={type} center={center}>
    {children}
  </Wrapper>
);

export default Container;

Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  center: PropTypes.object,
};
